.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
  width: calc(100% + 30px);
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  padding: 10px 15px;
  max-width: auto !important;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .col-xl-1 {
    width: 8.3333333333%;
  }
  .col-xl-2 {
    width: 16.6666666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.3333333333%;
  }
  .col-xl-5 {
    width: 41.6666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.3333333333%;
  }
  .col-xl-8 {
    width: 66.6666666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.3333333333%;
  }
  .col-xl-11 {
    width: 91.6666666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .col-xs-1 {
    width: 8.3333333333%;
  }
  .col-xs-2 {
    width: 16.6666666667%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.3333333333%;
  }
  .col-xs-5 {
    width: 41.6666666667%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-7 {
    width: 58.3333333333%;
  }
  .col-xs-8 {
    width: 66.6666666667%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-10 {
    width: 83.3333333333%;
  }
  .col-xs-11 {
    width: 91.6666666667%;
  }
  .col-xs-12 {
    width: 100%;
  }
}/*# sourceMappingURL=grid.css.map */