.btn-primary {
    border-radius: 5px;
    background-color: #ffcb5b;
    font-size: 18px;
    line-height: 1.5;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
    color: black;
    padding: 16px 40px;
    transition: 0.3s all ease-out;
    letter-spacing: 1.45px;
    position: relative;
    overflow: hidden !important;
  }
  @media screen and (max-width: 992px) {
    .btn-primary{
      padding: 14px 20px;
      font-size: 16px;
    }
  }
  
  .btn-primary:hover {
    box-shadow: 0px 8px 16px 0 rgba(0, 153, 46, 0.25);
    background-color: #ffca58;
  }
  
  .btn-primary:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: -1;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
  }
  
  .btn-primary:hover:before {
    z-index: 1;
    animation: imageH 1s;
    -webkit-animation: imageH 1s;
    -moz-animation: imageH 1s;
    -ms-animation: imageH 1s;
    -o-animation: imageH 1s;
  }
  
  @-webkit-keyframes imageH {
    100% {
      left: 125%;
    }
  }
  
  @keyframes imageH {
    100% {
      left: 125%;
    }
  }
  
  .border-link {
    position: relative;
  }
  
  .border-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    background-color: white;
    height: 2px;
    transform: scaleX(0) translateZ(0);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  
  .border-link:hover:before {
    transform: scaleX(1) translateZ(0);
    transform-origin: 0 0;
  }
  
  .pulse_effect {
    display: none;
  }
  
  @media screen and (min-width: 1280px) {
    .pulse_effect {
      border-radius: 50%;
      position: absolute;
      height: 40px;
      width: 40px;
      left: 0px;
      top: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: none;
      transform: none;
      z-index: -1;
      display: block;
    }
    .pulse_effect:after {
      content: "";
      border-radius: 50%;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
      box-shadow: 0 0 25px 2px #db429c;
      animation-delay: 1s;
    }
  }
  
  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.3, 0.3);
      transform: scale(0.3, 0.3);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.3, 0.3);
      transform: scale(0.3, 0.3);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.1, 1.1);
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
  }
  