* {
  outline: none;
  font-family: "Poppins", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  word-break: break-word;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
  line-height: 1.2;
  scrollbar-width: thin;
}

.container{
  max-width: 1600px;
  margin: auto;
  padding: 0px 6%;
}
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: table-cell;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.justify-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.justify-end {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

.align-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
  font-weight: 800 !important;
}

.semi-bold {
  font-weight: 700 !important;
}

.thin {
  font-weight: 400 !important;
}

.center {
  text-align: center !important;
}

.right {
  text-align: right !important;
}

.left {
  text-align: left !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}
.pointer {
  cursor: pointer !important;
}

.box-shadow {
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
}