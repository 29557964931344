main {
    min-height: calc(100vh - 100px);
}

.banner-section {
    padding: 100px 0px 60px;
    background: url("./../../assets/images/banner-bg-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;

    @media screen and (max-width: 768px) {
        padding: 40px 0px 30px;
    }

    .row {
        align-items: center;
    }

    h1 {
        font-size: 35px;
        margin-bottom: 30px;
        font-weight: 500;
        line-height: 1.2;

        @media screen and (max-width: 1300px) {
            font-size: 28px;
        }

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 40px;
        line-height: 1.8;

        @media screen and (max-width: 1300px) {
            font-size: 17px;
        }

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    .banner-img-cover {
        width: 80%;
        max-width: 400px;
        margin: auto;
        border-radius: 100px 0px;
        border-top: 5px solid #fbc02d;
        border-bottom: 5px solid #fbc02d;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .feature-icon {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .fa {
            font-size: 28px;
            color: #fbc02d;

            @media screen and (max-width: 768px) {
                width: 30px;
            }
        }

        h4 {
            font-size: 16px;
            margin-top: 5px;
            margin-left: 20px;
        }
    }
}

.about-section {
    padding: 100px 0px;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: 600;
        text-align: center;
    }

    p {
        line-height: 1.7;
        color: #5f676d;
        font-size: 16px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.welcome-section {
    padding: 100px 0px;
    background: #f1f3f5;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    h4 {
        color: #8d959b;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: 500;
    }

    button {
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        max-width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
        margin-top: -6px;

        li {
            color: #8d959b;
            font-size: 18px;
            padding-top: 6px;
            padding-bottom: 6px;
            white-space: nowrap;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                color: black;
            }

            &:not(:first-child) {
                padding-left: 20px;
            }

            &:not(:last-child) {
                padding-right: 20px;
                border-right: 1px solid #d3d3d3;
            }
        }
    }

    p {
        line-height: 1.7;
        color: #5f676d;
        font-size: 16px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.card-section {
    padding: 100px 0px;
    background: url("./../../assets/images/banner-bg-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: 500;
        text-align: center;
        color: white;
    }

    .single-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 200px;
        padding: 20px;
        background: white;
        border: 1px solid #e2e3e3;
        border-radius: 5px;
        transition: 0.3s;

        @media screen and (max-width: 576px) {
            height: 160px;
        }

        &:hover {
            border-color: #fff;
            box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
        }

        .fa {
            font-size: 32px;
            margin-bottom: 20px;
            color: #fbc02d;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;
        }

        h5 {
            line-height: 1.4;
            color: #5f676d;
            font-size: 16px;
            text-align: center;
        }
    }
}

.counter-section {
    padding: 100px 0px;
    counter-reset: sectionCounting 0;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    p {
        font-size: 15px;
        line-height: 1.5;
        color: #5f676d;
    }

    .col-3 {
        counter-increment: sectionCounting;
        margin-top: 80px;

        @media screen and (max-width: 1200px) {
            margin-top: 70px;
        }

        @media screen and (max-width: 992px) {
            margin-top: 60px;
        }

        &::before {
            content: "0"counter(sectionCounting) ".";
            position: absolute;
            font-size: 150px;
            font-weight: 500;
            transform: translate(0, -60%);
            color: #f1f3f5;

            @media screen and (max-width: 1200px) {
                font-size: 120px;
                transform: translate(0px, -60%);
            }

            @media screen and (max-width: 992px) {
                font-size: 100px;
            }
        }

        h2 {
            position: relative;
            z-index: 9;
        }
    }
}

.our-services-section {
    padding: 100px 0px 40px;
    background: #f1f3f5;

    @media screen and (max-width: 768px) {
        padding: 40px 0px 20px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 30px;
        font-weight: 600;
        text-align: center;
    }

    h4 {
        font-weight: 500;
    }

    .single-service {
        padding: 20px 0px;

        h5 {
            font-size: 17px;
            margin-bottom: 20px;
        }

        p {
            font-size: 15px;
            line-height: 1.5;
            color: #5f676d;
        }

    }

    .img-cover {
        img {
            max-width: 100%;
            width: auto;
        }
    }
}

.any-question-section {
    padding: 100px 0px;
    // background: #f1f3f5;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    h1 {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 30px;
        text-align: center;
        width: 800px;
        max-width: 100%;
        margin: 0px auto 30px auto;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
        }
    }

    form {
        width: 600px;
        margin: auto;
        max-width: 100%;

        input {
            padding: 15px;
            background: white;
            border: 1px solid #d3d3d3;
            outline: none;
            font-size: 16px;
            width: 100%;
        }

        button {
            width: 100%;
        }
    }
}

.why-choose-section {
    background: #f1f3f5;
    padding: 100px 0px 40px;

    @media screen and (max-width: 768px) {
        padding: 40px 0px 20px;
    }

    h4 {
        color: #8d959b;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 30px;
        font-weight: 500;
    }

    ul {
        li {
            padding: 5px 0px;
            line-height: 1.7;
            color: #5f676d;
            font-size: 16px;
            padding-left: 30px;
            position: relative;

            &::before {
                content: "✓";
                font-size: 25px;
                position: absolute;
                left: 0px;
                top: -2px;
                transform: rotateX(54deg);
                color: #fbc02d;
            }
        }
    }

    .video-cover {
        max-width: 100%;
        margin-bottom: 20px;

        iframe {
            max-width: 100%;
            width: 100%;
            height: auto;
            min-height: 320px;

            @media screen and (max-width: 576px) {
                min-height: 220px;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .col-6 {
            &:first-child {
                order: 1;
            }
        }
    }
}



.review-section {
    padding: 100px 0px;

    @media screen and (max-width: 768px) {
        padding: 40px 0px;
    }

    .single-review {
        height: 100%;
        padding: 20px;
        border: 1px solid #e2e3e3;
        border-radius: 5px;

        .fa {
            font-size: 30px;
            margin-bottom: 15px;
            color: #fbc02d;
        }

        p {
            font-size: 15px;
            color: #60686e;
            margin-bottom: 15px;
            line-height: 1.5;
        }

        h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        h6 {
            color: #60686e;
            font-size: 14px;
        }
    }
}

.building-section {
    padding: 100px 0px 40px;

    @media screen and (max-width: 768px) {
        padding: 40px 0px 20px;
    }

    h3 {
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: 600;
        text-align: center;
    }

    .gallery-img {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 20px -10px;

        .single-img {
            width: calc(100% / 6);
            padding: 30px 15px;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: auto;
                max-width: 100%;
                max-height: 100%;
                height: auto;
            }

            @media screen and (max-width: 1200px) {
                width: calc(100% / 5);
                padding: 30px 15px;
                height: 160px;
            }
            @media screen and (max-width: 992px) {
                width: calc(100% / 4);
                padding: 25px 15px;
                height: 150px;
            }
            @media screen and (max-width: 768px) {
                width: calc(100% / 3);
                padding: 22px 15px;
                height: 140px;
            }
            @media screen and (max-width: 576px) {
                width: calc(100% / 2);
                padding: 20px 15px;
                height: 120px;
            }
        }
    }
}

footer {
    padding: 40px 0px;
    background-color: #14253d;
    color: #b1b1b1;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .social-icons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                display: block;

                &:not(:first-child) {
                    margin-left: 25px;
                }

                img {
                    display: table-cell;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;
            justify-content: center;

            p {
                order: 1;
                text-align: center;
            }

            .social-icons {
                margin-bottom: 20px;
            }
        }
    }
}