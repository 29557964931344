.page-header {
    text-align: center;
    padding: 10px 0px;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    background-color: white;
    position: sticky;
    display: block;
    top: 0;
    z-index: 99;
    height: 90px;
    .logo-cover {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            width: 70px;
            display: table-cell;
            cursor: pointer;
            margin-right: 15px;
        }
        h2{
            font-size: 36px;
            font-weight: bold;
            font-family: 'BernhardFashion';
           
        }
    }
    .hamburger-cover {
        img {
            width: 30px;
        }
    }
    .dropdown-cover {
        position: relative;
        .drop-btn {
            position: relative;
            text-align: left;
            cursor: pointer;
            font-weight: 500;
            color: #11181c;
            font-size: 16px;
            padding: 12px 15px;
            &::after {
                content: "▼";
                position: absolute;
                right: 0px;
                font-size: 13px;
                top: 48%;
                transform: scaleY(0.7) translateY(-50%);
            }
        }
        &:hover {
            .drop-content {
                display: block;
            }
        }
        .drop-content {
            display: none;
            transition: 0.3s;
            position: absolute;
            background-color: white;
            top: 45px;
            left: 10px;
            width: 200px;
            max-width: calc(100vw - 40px);
            box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.15);
            li {
                text-align: left;
                width: 100%;
                cursor: pointer;
                padding: 12px 15px;
                font-size: 14px;
                color: #11181c;
                display: inline-block;
                text-align: left;
                &:hover {
                    background-color: #f1f3f5;
                    color: black;
                }
            }
        }
    }
    .links-cover {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0px;
        .single-link {
            padding: 0px 10px;
            &:last-child{
                padding-right: 0px;
            }
        }
        a {
            padding: 12px 10px;
            cursor: pointer;
            font-weight: 500;
            color: #11181c;
            font-size: 16px;
            text-decoration: none;
            &:hover {
                border-bottom: 2px solid #fbc02d;
            }
        }
    }
    .for-mobile {
        display: none;
        .sidebar-cover {
            background-color: rgba(0, 0, 0, 0.25);
            top: 80px;
            left: 0px;
            z-index: 99;
            width: 100%;
            height: calc(100vh - 80px);
            position: fixed;
            display: none;
            border-top: 1px solid #d3d3d3;
            animation: fadeIn ease 0.2s;
            -webkit-animation: fadeIn ease 0.3s;
            -moz-animation: fadeIn ease 0.3s;
            -o-animation: fadeIn ease 0.3s;
            -ms-animation: fadeIn ease 0.3s;
            &.active {
                display: block;
            }
            .sidebar {
                padding: 20px 0px;
                width: 300px;
                height: 100%;
                margin-left: auto;
                background-color: white;
                ul {
                    height: calc(100vh - 83px);
                    overflow: auto;
                    a {
                        padding: 12px 12px 12px 20px;
                        width: 100%;
                        font-size: 15px;
                        display: block;
                        text-align: left;
                        font-weight: 500;
                        color: #11181c;
                    }
                    .drop-content{
                        height: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .logo-cover {
        img {
            width: 60px;
            margin-right: 12px;
        }
        h2{
            font-size: 32px;
        }
    }
}
@media screen and (max-width: 991px) {
    .page-header {
        height: 80px;
        .logo-cover {
            img {
                width: 50px;
                margin-right: 10px;
            }
            h2{
                font-size: 28px;
            }
        }
        .links-cover {
            display: none;
        }
        .for-mobile {
            width: auto;
            display: block;
            .sidebar-cover {
                top: 80px;
                height: calc(100vh - 80px);
            }
        }
        .dropdown-cover {
            .drop-btn {
                padding: 12px 30px 12px 20px;
                font-size: 15px;
                &::after {
                    right: 12px;
                }
            }
            .drop-content {
                right: 10px;
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .page-header {
        height: 60px;
        padding: 7px 0;
        .logo-cover {
            img {
                width: 45px;
                margin-right: 10px;
            }
            h2{
                font-size: 24px;
            }
        }
        .for-mobile {
            .sidebar-cover {
                top: 60px;
                height: calc(100vh - 60px);
                .sidebar{
                    width: 250px;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
