.company-page .tabcontent{
  padding: 20px 10px;
  overflow: auto;
  max-height: calc(100vh - 139px);
}

.company-page .MuiTab-root{
  font-weight: 500;
  color: black;
}

.detail-container {
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #5f676d;
}

.detail-container .img {
  width: 400px;
  max-width: 100%;
}
@media screen and (max-width: 990px) {
  .detail-container .img{
    width: 300px;
    max-width: 60%;
  }
  .company-page .tabcontent{
    max-height: calc(100vh - 130px);
  }
}
@media screen and (max-width: 576px) {
  .detail-container .img{
    width: 250px;
  }
  .company-page .space-between img{
    max-width: 40%;
  }
  .company-page .tabcontent{
    max-height: calc(100vh - 110px);
  }
  .company-page .MuiTabs-root{
    min-height: 36px;
  }
  .company-page .MuiTab-root{
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 500;
    color: black;
    min-height: 36px;
  }
}

.detail-container .img img {
  max-width: 100%;
}

.detail-container .text {
  text-align: justify;
  flex: 1;
  padding-left: 30px;
}

.detail-container .text .bname {
  font-weight: bold;
  padding-bottom: 10px;
}

.detail-container .text .sub-bname {
  font-family: inherit;
  color: steelblue;
  padding-bottom: 10px;
}

@media screen and (max-width: 991px) {
  .detail-container .img {
    margin: auto;
  }

  .detail-container .text {
    width: 100%;
    flex: auto;
    padding-top: 30px;
    padding-left: 0px;
  }
}

.carousel .awssld__content {
  background-color: white;
  height: 340px !important;
}

.awssld--organic-arrows {
  background-color: white;
  height: 340px !important;
}

.gallery-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px -10px;
}

.gallery-img .list-img {
  width: 110px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
}

.outer-list {
  list-style-type: disc;
  text-decoration: underline;
}

.inner-list {
  list-style-type: circle;
}

.inner-listone {
  list-style-type: disc;
}

.inner-ul {
  margin-left: 20px;
}

.img-text {
  display: flex;
}

.img-text .imgsection {
  width: 100%;
  padding: 10px;
}

.img-text .txtsection {
  width: 650%;
}


@media screen and (max-width: 576px) {
  .gallery-img .list-img {
    width: 25vw;
    height: 25vw;
  }
}

@media screen and (max-width: 430px) {
  .gallery-img .list-img {
    width: 24vw;
    height: 24vw;
  }
}

@media screen and (max-width: 360px) {
  .gallery-img .list-img {
    width: 23vw;
    height: 23vw;
  }
}

.gallery-img .list-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tdf {
  width: 40%;
  font-size: 14px;
  padding-left: 10px;
  border-bottom: 1px solid
}

.tds {
  width: 60%;
  font-size: 14px;
  padding-left: 10px;
  border-bottom: 1px solid;
  border-left: 1px solid;

}

.tdfRow {
  width: 40%;
  font-size: 20px;
  font-weight: 600;
  border: ridge;
  padding-left: 10px;
}

.tdsRow {
  width: 60%;
  font-size: 20px;
  font-weight: 600;
  border: ridge;
  padding-left: 10px;
}