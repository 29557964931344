@charset "UTF-8";
main {
  min-height: calc(100vh - 100px);
}

.banner-section {
  padding: 100px 0px 60px;
  background: url("./../../assets/images/banner-bg-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
}
@media screen and (max-width: 768px) {
  .banner-section {
    padding: 40px 0px 30px;
  }
}
.banner-section .row {
  align-items: center;
}
.banner-section h1 {
  font-size: 35px;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 1300px) {
  .banner-section h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .banner-section h1 {
    font-size: 24px;
  }
}
.banner-section h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 1.8;
}
@media screen and (max-width: 1300px) {
  .banner-section h3 {
    font-size: 17px;
  }
}
@media screen and (max-width: 768px) {
  .banner-section h3 {
    font-size: 16px;
  }
}
.banner-section .banner-img-cover {
  width: 80%;
  max-width: 400px;
  margin: auto;
  border-radius: 100px 0px;
  border-top: 5px solid #fbc02d;
  border-bottom: 5px solid #fbc02d;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .banner-section .banner-img-cover {
    display: none;
  }
}
.banner-section .banner-img-cover img {
  width: 100%;
}
.banner-section .feature-icon {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.banner-section .feature-icon .fa {
  font-size: 28px;
  color: #fbc02d;
}
@media screen and (max-width: 768px) {
  .banner-section .feature-icon .fa {
    width: 30px;
  }
}
.banner-section .feature-icon h4 {
  font-size: 16px;
  margin-top: 5px;
  margin-left: 20px;
}

.about-section {
  padding: 100px 0px;
}
@media screen and (max-width: 768px) {
  .about-section {
    padding: 40px 0px;
  }
}
.about-section h3 {
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 600;
  text-align: center;
}
.about-section p {
  line-height: 1.7;
  color: #5f676d;
  font-size: 16px;
}
.about-section p:not(:last-child) {
  margin-bottom: 20px;
}

.welcome-section {
  padding: 100px 0px;
  background: #f1f3f5;
}
@media screen and (max-width: 768px) {
  .welcome-section {
    padding: 40px 0px;
  }
}
.welcome-section h4 {
  color: #8d959b;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}
.welcome-section h3 {
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 500;
}
.welcome-section button {
  margin-bottom: 20px;
}
.welcome-section ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  max-width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-top: -6px;
}
.welcome-section ul li {
  color: #8d959b;
  font-size: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
}
.welcome-section ul li:hover {
  color: black;
}
.welcome-section ul li:not(:first-child) {
  padding-left: 20px;
}
.welcome-section ul li:not(:last-child) {
  padding-right: 20px;
  border-right: 1px solid #d3d3d3;
}
.welcome-section p {
  line-height: 1.7;
  color: #5f676d;
  font-size: 16px;
}
.welcome-section p:not(:last-child) {
  margin-bottom: 20px;
}

.card-section {
  padding: 100px 0px;
  background: url("./../../assets/images/banner-bg-2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 768px) {
  .card-section {
    padding: 40px 0px;
  }
}
.card-section h3 {
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 500;
  text-align: center;
  color: white;
}
.card-section .single-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  padding: 20px;
  background: white;
  border: 1px solid #e2e3e3;
  border-radius: 5px;
  transition: 0.3s;
}
@media screen and (max-width: 576px) {
  .card-section .single-card {
    height: 160px;
  }
}
.card-section .single-card:hover {
  border-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.card-section .single-card .fa {
  font-size: 32px;
  margin-bottom: 20px;
  color: #fbc02d;
}
.card-section .single-card h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.card-section .single-card h5 {
  line-height: 1.4;
  color: #5f676d;
  font-size: 16px;
  text-align: center;
}

.counter-section {
  padding: 100px 0px;
  counter-reset: sectionCounting 0;
}
@media screen and (max-width: 768px) {
  .counter-section {
    padding: 40px 0px;
  }
}
.counter-section h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}
.counter-section p {
  font-size: 15px;
  line-height: 1.5;
  color: #5f676d;
}
.counter-section .col-3 {
  counter-increment: sectionCounting;
  margin-top: 80px;
}
@media screen and (max-width: 1200px) {
  .counter-section .col-3 {
    margin-top: 70px;
  }
}
@media screen and (max-width: 992px) {
  .counter-section .col-3 {
    margin-top: 60px;
  }
}
.counter-section .col-3::before {
  content: "0" counter(sectionCounting) ".";
  position: absolute;
  font-size: 150px;
  font-weight: 500;
  transform: translate(0, -60%);
  color: #f1f3f5;
}
@media screen and (max-width: 1200px) {
  .counter-section .col-3::before {
    font-size: 120px;
    transform: translate(0px, -60%);
  }
}
@media screen and (max-width: 992px) {
  .counter-section .col-3::before {
    font-size: 100px;
  }
}
.counter-section .col-3 h2 {
  position: relative;
  z-index: 9;
}

.our-services-section {
  padding: 100px 0px 40px;
  background: #f1f3f5;
}
@media screen and (max-width: 768px) {
  .our-services-section {
    padding: 40px 0px 20px;
  }
}
.our-services-section h3 {
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}
.our-services-section h4 {
  font-weight: 500;
}
.our-services-section .single-service {
  padding: 20px 0px;
}
.our-services-section .single-service h5 {
  font-size: 17px;
  margin-bottom: 20px;
}
.our-services-section .single-service p {
  font-size: 15px;
  line-height: 1.5;
  color: #5f676d;
}
.our-services-section .img-cover img {
  max-width: 100%;
  width: auto;
}

.any-question-section {
  padding: 100px 0px;
}
@media screen and (max-width: 768px) {
  .any-question-section {
    padding: 40px 0px;
  }
}
.any-question-section h1 {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
  width: 800px;
  max-width: 100%;
  margin: 0px auto 30px auto;
}
@media screen and (max-width: 1200px) {
  .any-question-section h1 {
    font-size: 28px;
  }
}
.any-question-section form {
  width: 600px;
  margin: auto;
  max-width: 100%;
}
.any-question-section form input {
  padding: 15px;
  background: white;
  border: 1px solid #d3d3d3;
  outline: none;
  font-size: 16px;
  width: 100%;
}
.any-question-section form button {
  width: 100%;
}

.why-choose-section {
  background: #f1f3f5;
  padding: 100px 0px 40px;
}
@media screen and (max-width: 768px) {
  .why-choose-section {
    padding: 40px 0px 20px;
  }
}
.why-choose-section h4 {
  color: #8d959b;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}
.why-choose-section h3 {
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: 500;
}
.why-choose-section ul li {
  padding: 5px 0px;
  line-height: 1.7;
  color: #5f676d;
  font-size: 16px;
  padding-left: 30px;
  position: relative;
}
.why-choose-section ul li::before {
  content: "✓";
  font-size: 25px;
  position: absolute;
  left: 0px;
  top: -2px;
  transform: rotateX(54deg);
  color: #fbc02d;
}
.why-choose-section .video-cover {
  max-width: 100%;
  margin-bottom: 20px;
}
.why-choose-section .video-cover iframe {
  max-width: 100%;
  width: 100%;
  height: auto;
  min-height: 320px;
}
@media screen and (max-width: 576px) {
  .why-choose-section .video-cover iframe {
    min-height: 220px;
  }
}
@media screen and (max-width: 992px) {
  .why-choose-section .col-6:first-child {
    order: 1;
  }
}

.review-section {
  padding: 100px 0px;
}
@media screen and (max-width: 768px) {
  .review-section {
    padding: 40px 0px;
  }
}
.review-section .single-review {
  height: 100%;
  padding: 20px;
  border: 1px solid #e2e3e3;
  border-radius: 5px;
}
.review-section .single-review .fa {
  font-size: 30px;
  margin-bottom: 15px;
  color: #fbc02d;
}
.review-section .single-review p {
  font-size: 15px;
  color: #60686e;
  margin-bottom: 15px;
  line-height: 1.5;
}
.review-section .single-review h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.review-section .single-review h6 {
  color: #60686e;
  font-size: 14px;
}

.building-section {
  padding: 100px 0px 40px;
}
@media screen and (max-width: 768px) {
  .building-section {
    padding: 40px 0px 20px;
  }
}
.building-section h3 {
  font-size: 26px;
  margin-bottom: 40px;
  font-weight: 600;
  text-align: center;
}
.building-section .gallery-img {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px -10px;
}
.building-section .gallery-img .single-img {
  width: 16.6666666667%;
  padding: 30px 15px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.building-section .gallery-img .single-img img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.imageChoose img{
  width: 180;
}
@media screen and (max-width: 1200px) {
  .imageChoose img{
    width: 180;
  }
}
@media screen and (max-width: 92px) {
  .imageChoose{
    width: 250;
  }
}
@media screen and (max-width: 768px) {
  .imageChoose{
    width: 200;
  }
}
@media screen and (max-width: 576px) {
  .imageChoose{
    width: 180;
  }
}
@media screen and (max-width: 1200px) {
  .building-section .gallery-img .single-img {
    width: 20%;
    padding: 30px 15px;
    height: 160px;
  }
}
@media screen and (max-width: 992px) {
  .building-section .gallery-img .single-img {
    width: 25%;
    padding: 25px 15px;
    height: 150px;
  }
}
@media screen and (max-width: 768px) {
  .building-section .gallery-img .single-img {
    width: 33.3333333333%;
    padding: 22px 15px;
    height: 140px;
  }
}
@media screen and (max-width: 576px) {
  .building-section .gallery-img .single-img {
    width: 50%;
    padding: 20px 15px;
    height: 120px;
  }
}

footer {
  padding: 40px 0px;
  background-color: #14253d;
  color: #b1b1b1;
}
footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .container .social-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
footer .container .social-icons a {
  display: block;
}
footer .container .social-icons a:not(:first-child) {
  margin-left: 25px;
}
footer .container .social-icons a img {
  display: table-cell;
  width: 30px;
  height: 30px;
}
.myservice{
  padding-left: 25px;
}
.myserList {
  list-style: disc;
  color: gray;
}
@media screen and (max-width: 720px) {
  footer .container {
    flex-direction: column;
    justify-content: center;
  }
  footer .container p {
    order: 1;
    text-align: center;
  }
  footer .container .social-icons {
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=home.css.map */